import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Container, Form, FormFeedback, FormGroup, Input, Label, Spinner } from 'reactstrap';
import AppNavbar from './AppNavbar';

const MSEndpoint = require("./config").MSEndpoint
class UserEdit extends Component {

    emptyItem = {
        id: '',
        firstName: '',
        lastName: '',
        email: ''
    };

    constructor(props) {
        super(props);
        this.state = {
            item: this.emptyItem,
            orgNameWarningMessageVisible: false,
            status: 200,
            loading: false,
            loadingTime: 0
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.timer = null;
    }

    async componentDidMount() {
        if (this.props.match.params.email !== 'new' && this.props.match.params.email !== 'failed') {
            const config = {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }
            var response = await fetch(MSEndpoint + `/user?email=${this.props.match.params.email}`, config)
            if (response.status === 426) {
                this.setState({ item: this.emptyItem, status: 426 });
            } else {
                var user = await response.json();
                if (user === undefined) user = this.emptyItem
                this.setState({ item: user, status: 200 });
            }
        }
    }

    componentWillUnmount() {
        if (this.timer) {
            clearInterval(this.timer);
        }
    }

    orgNameValidator(newOrgName) {
        newOrgName = newOrgName.toLowerCase()
        const regex = /[^a-z0-9_]/g;
        const cleanedString = newOrgName.replace(regex, '');

        if (regex.test(newOrgName)) {
            this.setState(oldState => ({ ...oldState, orgNameWarningMessageVisible: true }))
        } else {
            this.setState(oldState => ({ ...oldState, orgNameWarningMessageVisible: false }))
        }

        return cleanedString;
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let newItem = { ...this.state.item };
        if (name === "orgName") {
            newItem[name] = this.orgNameValidator(value)
        } else {
            newItem[name] = value
        }
        this.setState({ item: newItem });
    }

    async handleSubmit(event) {
        event.preventDefault();
        const { item } = this.state;
        const end_point = '/createUser'
        this.setState((oldState) => ({ ...oldState, loading: true }))
        this.timer = setInterval(() => {
            this.setState((oldState) => ({ ...oldState, loadingTime: oldState.loadingTime + 1 }));
        }, 1000);

        await fetch(MSEndpoint + end_point, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(item),
        }).then(res => {
            if (res.status == 200) {
                this.props.history.push('/users')
            }
            else {
                this.props.history.push('/users/failed')
            }
            this.setState((oldState) => ({ ...oldState, loading: false, loadingTime: 0 }))
            clearInterval(this.timer)
        });
    }

    render() {
        const { item, status } = this.state;
        const titleContent = status === 426 ? "Org Updating" : item.id ? 'Edit User' : 'Add User'
        const title = <h2>{titleContent}</h2>;


        const isErrorMessagePresent = (this.props.match.params.email === 'failed')

        return <div>
            <AppNavbar />
            <Container>
                {title}
                {isErrorMessagePresent && (
                    <p> Last {titleContent} Failed </p>
                )}
                <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                        <Label for="firstName">FirstName</Label>
                        <Input type="text" name="firstName" id="firstName" value={item.firstName || ''}
                            onChange={this.handleChange} autoComplete="FirstName" />
                    </FormGroup>
                    <FormGroup>
                        <Label for="lastName">LastName</Label>
                        <Input type="text" name="lastName" id="lastName" value={item.lastName || ''}
                            onChange={this.handleChange} autoComplete="LastName" />
                    </FormGroup>
                    <FormGroup>
                        <Label for="orgName">OrgName</Label>
                        <Input type="text" name="orgName" id="orgName" value={item.orgName || ''}
                            onPaste={this.handleChange} onChange={this.handleChange} autoComplete="orgName"
                            invalid={this.state.orgNameWarningMessageVisible} />
                        <FormFeedback>Only Lowercase Letters and _ are allowed</FormFeedback>
                    </FormGroup>
                    <FormGroup>
                        <Label for="email">Email</Label>
                        <Input type="text" name="email" id="email" value={item.email || ''}
                            onChange={this.handleChange} autoComplete="email" />
                    </FormGroup>
                    <FormGroup style={{ display: 'flex', alignItems: 'center' }}>
                        <button className="btn btn-primary" type="submit" disabled={this.state.loading} onClick={this.handleSubmit} style={{ width: '80px' }}>
                            {this.state.loading ?
                                <Spinner size="sm" color='light' /> : <>Save</>}
                        </button>
                        &nbsp;
                        <Button color="secondary" tag={Link} disabled={this.state.loading} to="/users">Cancel</Button>
                        &nbsp;
                        {this.state.loading && (<div>{this.state.loadingTime}s</div>)}
                    </FormGroup>
                </Form>
            </Container>
        </div>
    }

}
export default withRouter(UserEdit);
