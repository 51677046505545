import { withAuth0 } from '@auth0/auth0-react';
import { Autocomplete, Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, createFilterOptions } from "@mui/material";
import React from 'react';
import AppNavbar from './AppNavbar';
import { MSEndpoint } from './config';

const filter = createFilterOptions<User>()

export type User = {
    id?: string,
    role?: string,
    email?: string
}

export type Admin = {
    email?: string
}

function AdminList() {
    const [state, setState] = React.useState<{ users: User[], admins: Admin[] }>({ users: [], admins: [] })
    const [emailToMakeAdmin, setEmailToMakeAdmin] = React.useState<string | undefined>();
        
    const config = {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
    }
        
    
    React.useEffect(() => {
        fetch(MSEndpoint+'/user', config)
            .then(response => {
                if (response.status === 401) {
                    response.text().then(data => alert(`Unauthorized Access. ${data}`))
                } else {
                    response.json().then(data => setState(old => ({ ...old, users: data })))
                }
            });

        fetch(MSEndpoint+'/admin/list', config)
            .then(response => {
                if (response.status === 401) {
                    response.text().then(data => alert(`Unauthorized Access. ${data}`))
                } else {
                    response.json().then(data => setState(old => ({ ...old, admins: data })))
                }
            });
    }, [])
    

    const removeAdmin = async(admin) => {
        await fetch(MSEndpoint+`/admin/remove`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(admin),
        }).then(response => {
            if (response.status === 401) {
                response.text().then(data => alert(`Unauthorized Access. ${data}`))
            } else {
                window.location.reload();
            }
        });
    }

    const addAdmin = async(admin) => {
        await fetch(MSEndpoint+`/admin/add`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(admin),
        }).then(response => {
            if (response.status === 401) {
                response.text().then(data => alert(`Unauthorized Access. ${data}`))
            } else {
                window.location.reload();
            }
        });
    }

    const addAdminUser = () => {
        console.log(emailToMakeAdmin)
        if(emailToMakeAdmin) {
            addAdmin(emailToMakeAdmin)
            setEmailToMakeAdmin(undefined)
        }
    }
    
    if (state?.users === undefined) {
        return <p>Loading...</p>;
    }

    return (
        <>
            <AppNavbar/>
            <h3>Admins</h3>
            <Box display="flex" flexDirection="column" gap={2}>
                <Box>
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                            <TableRow>
                                <TableCell>Email</TableCell>
                                <TableCell align="right">Actions</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {state.admins.map((admin) => (
                                <TableRow
                                    key={admin.email}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">{admin?.email || "NA"}</TableCell>
                                    <TableCell align="right">
                                        <Button onClick={() => removeAdmin(admin)}>Remove</Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Box display="flex" gap={2} alignItems="center">
                    <Box sx={{ width: "100%" }}>
                        <Autocomplete
                            options={state.users?.map(user => user.email)}
                            filterSelectedOptions
                            fullWidth
                            selectOnFocus
                            freeSolo
                            handleHomeEndKeys
                            renderInput={(params) => <TextField label="Enter Email" {...params}/>}
                            onChange={(event, value, reason, details) => {
                                setEmailToMakeAdmin(value ?? undefined)
                            }}
                            onInputChange={(event, value, reason) => setEmailToMakeAdmin(value ?? undefined)}
                        />
                        </Box>
                        <Box sx={{ height: "100%" }}>
                            <Button onClick={addAdminUser}>Add</Button>
                        </Box>
                </Box>
            </Box> 
        </> 
    );
}

export default withAuth0(AdminList);
