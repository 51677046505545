import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Container, Form, FormGroup, Input, Label } from 'reactstrap';
import AppNavbar from './AppNavbar';

const MSEndpoint = require("./config").MSEndpoint
class OrgEdit extends Component {

    emptyItem = {
        id:'',
        firstName: '',
        lastName: '',
        email:''
    };

    constructor(props) {
        super(props);
        this.state = {
            item: this.emptyItem,
            status: 200
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        if (this.props.match.params.email !== 'new' && this.props.match.params.email !== 'failed') {
            const config = {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }
            var response = await fetch(MSEndpoint+`/org?orgName=${this.props.match.params.name}`, config)
            if(response.status===426) {
                this.setState({item: this.emptyItem, status: 426});   
            } else {
                var org = await response.json();
                if (org === undefined) org=this.emptyItem
                this.setState({item: org, status: 200});
            }
        } 
    }

    orgNameValidator(newOrgName) {
        return newOrgName
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        const newState = {...this.state.item, [name]: value}

        console.log(newState, name, value)
        this.setState({item: newState});
    }

    async handleSubmit(event) {
        event.preventDefault();
        const {item} = this.state;
        const end_point = '/org'
        await fetch(MSEndpoint + end_point, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(item),
        }).then(res => { 
            if(res.status == 200) {
                this.props.history.push('/orgs') 
            }
            else {
                this.props.history.push('/orgs/failed')
            }
        });
    }

    render() {
        const {item, status} = this.state;
        const titleContent = status===426 ? "Org Updating": item.id ? 'Edit User' : 'Add User'
        const title = <h2>{titleContent}</h2>;

        
        const isErrorMessagePresent = (this.props.match.params.email === 'failed')
    
        return <div>
            <AppNavbar/>
            <Container>
                {title}
                {isErrorMessagePresent && (
                    <p> Last {titleContent} Failed </p>
                )}
                <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                        <Label for="name">Name</Label>
                        <Input disabled type="name" name="name" id="name" value={item.name || ''}
                               onChange={this.handleChange} autoComplete="name"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="status">Status</Label>
                        <Input disabled type="status" name="status" id="lastName" value={item.status || ''}
                               onChange={this.handleChange} autoComplete="status"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="dbVersion">DB Version</Label>
                        <Input type="dbVersion" name="dbVersion" id="dbVersion" value={item.dbVersion || ''}
                               onChange={this.handleChange} autoComplete="dbVersion"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="featureConfig">Feature Config (tableStats, decomposeQuestions, multiTableInput, writeBack, showLogs, showInsights, newChatController, maxTablePerAgent )</Label>
                        <Input type="featureConfig" name="featureConfig" id="featureConfig" value={item.featureConfig || ''}
                               onChange={this.handleChange} autoComplete="featureConfig"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="defaultLanguage">Default Language(python,PostgresSQL,ClickHouseSQL,SnowflakeSQL,SparkSQL)</Label>
                        <Input type="defaultLanguage" name="defaultLanguage" id="defaultLanguage" value={item.defaultLanguage || ''}
                               onChange={this.handleChange} autoComplete="defaultLanguage"/>
                    </FormGroup>
                    <FormGroup>
                        <Button color="primary" type="submit">Save</Button>{' '}
                        <Button color="secondary" tag={Link} to="/orgs">Cancel</Button>
                    </FormGroup>
                </Form>
            </Container>
        </div>
    }

}
export default withRouter(OrgEdit);
