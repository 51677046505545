import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Container, Form, FormGroup, Input, Label } from 'reactstrap';
import AppNavbar from '../AppNavbar';

const MSEndpoint = require("../config").MSEndpoint
class PromptRulesEdit extends Component {

    emptyItem = {
        id:'',
        regex: '',
        prefix: '',
        suffix:'',
        owner:'',
    };

    constructor(props) {
        super(props);
        this.state = {
            item: this.emptyItem,
            status: 200
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        if (this.props.match.params.id !== 'new' && this.props.match.params.id !== 'failed') {
            const config = {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            }
            var response = await fetch(MSEndpoint+`/singlePromptRule?id=${this.props.match.params.id}`, config)
            if(response.status===426) {
                this.setState({item: this.emptyItem, status: 426});
            } else {
                var promptTuningRule = await response.json();
                if (promptTuningRule === undefined) promptTuningRule=this.emptyItem
                this.setState({item: promptTuningRule, status: 200});
            }
        }
    }


    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let newItem = {...this.state.item};
        newItem[name]=value
        this.setState({item: newItem});
    }

    async handleSubmit(event) {
        event.preventDefault();
        var {item} = this.state;
        item.owner = localStorage.getItem('email')
        const end_point = '/promptRule'
        await fetch(MSEndpoint + end_point, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify(item),
        }).then(res => {
            if(res.status == 200) {
                this.props.history.push('/promptRules')
            }
            else {
                this.props.history.push('/promptRules/failed')
            }
        });
    }

    render() {
        const {item, status} = this.state;
        const titleContent = item.id ? 'Edit Prompt Rules' : 'Add Prompt Rules'
        const title = <h2>{titleContent}</h2>;


        const isErrorMessagePresent = (this.props.match.params.id === 'failed')

        return <div>
            <AppNavbar/>
            <Container>
                {title}
                {isErrorMessagePresent && (
                    <p> Last {titleContent} Failed </p>
                )}
                <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                        <Label for="regex">Regex</Label>
                        <Input type="text" name="regex" id="regex" value={item.regex || ''}
                               onChange={this.handleChange} autoComplete="regex"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="prefix">Prefix</Label>
                        <Input type="text" name="prefix" id="prefix" value={item.prefix || ''}
                               onChange={this.handleChange} autoComplete="Prefix"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="Suffix">Suffix</Label>
                        <Input type="text" name="suffix" id="suffix" value={item.suffix || ''}
                               onChange={this.handleChange} autoComplete="suffix"/>
                    </FormGroup>
                    <FormGroup>
                        <Button color="primary" type="submit">Save</Button>{' '}
                        <Button color="secondary" tag={Link} to="/promptRules">Cancel</Button>
                    </FormGroup>
                </Form>
            </Container>
        </div>
    }

}
export default withRouter(PromptRulesEdit);
