import { withAuth0 } from '@auth0/auth0-react';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup, Container, Table } from 'reactstrap';
import AppNavbar from '../AppNavbar';

const MSEndpoint = require("../config").MSEndpoint

class PromptRulesList extends Component {


    constructor(props) {
        super(props);
        this.state = {prompts: []};
        this.config = {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }

    }

    componentDidMount() {
        fetch(MSEndpoint+'/promptRule', this.config)
            .then(response => {
                if (response.status === 401) {
                    response.text().then(data => alert(`Unauthorized Access. ${data}`))
                } else {
                    response.json().then(data => this.setState({prompts: data}))
                }
            })
    }

    render() {
        const {prompts: prompts, isLoading} = this.state;
        const {logout} = this.props.auth0

        if (isLoading) {
            return <p>Loading...</p>;
        }

        const promptList = prompts.map(promptRule => {
            return <tr key={promptRule.id}>
                <td style={{whiteSpace: 'nowrap'}}>{promptRule.regex}</td>
                <td>{promptRule.prefix}</td>
                <td>{promptRule.suffix}</td>
                <td>{promptRule.owner}</td>
                <td>
                    <ButtonGroup>
                        <Button size="sm" color="primary" tag={Link} to={"/promptRules/" + promptRule.id}>Edit</Button>
                    </ButtonGroup>
                </td>
            </tr>
        });

        const handleLogout = () => {
            localStorage.removeItem('token')
            localStorage.removeItem('username')
            localStorage.removeItem('email')
            console.log("in management console logout")
            logout()

        }

        return (
            <div>
                <AppNavbar/>
                <Container fluid>
                    <div className="float-right">
                        <Button color="success" tag={Link} to="/promptRules/new">Add Prompt Rule</Button>
                        <Button color="danger" onClick={() => handleLogout()}>Logout</Button>
                    </div>
                    <h3>Prompt Rules</h3>
                    <Table className="mt-4">
                        <thead>
                        <tr>
                            <th width="20%">Regx</th>
                            <th width="20%">Prefix</th>
                            <th width="20%">Suffix</th>
                            <th width="20%">Owner</th>
                            <th width="20%">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {promptList}
                        </tbody>
                    </Table>
                </Container>
            </div>
        );
    }
}
export default withAuth0(PromptRulesList);
