
const domain = getDomainFromUrl(process.env.REACT_APP_AUTH0_DOMAIN !== undefined ? process.env.REACT_APP_AUTH0_DOMAIN : "https://stage-cimba.us.auth0.com")
const audience = process.env.REACT_APP_AUTH0_AUDIENCE !== undefined ? process.env.REACT_APP_AUTH0_AUDIENCE : "https://data-facade-security/"
export const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID !== undefined ? process.env.REACT_APP_AUTH0_CLIENT_ID : "xu2ONosWygSXhPdKea7JiWPlREEyroiA"

export function getConfig() {
  // Configure the audience here. By default, it will take whatever is in the config
  // (specified by the `audience` key) unless it's the default value of "YOUR_API_IDENTIFIER" (which
  // is what you get sometimes by using the Auth0 sample download tool from the quickstart page, if you
  // don't have an API).
  // If this resolves to `null`, the API page changes to show some helpful info about what to do
  // with the audience.
  return {
    domain: domain,
    clientId: clientId,
    ...(audience ? { audience } : null),
  };
}

function getDomainFromUrl(url) {
  const urlObj = new URL(url);
  return urlObj.hostname;
}