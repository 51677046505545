import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {Grid} from '@material-ui/core'
import { useAuth0 } from "@auth0/auth0-react"
import LoginButton from './components/LoginButton'


const useStyles = makeStyles(() => ({
    particles: {
        height: '100vh'
    },
    welcome_header: {
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
        width: '100vw',
        height: '100vh',
        zIndex: 5,
        fontSize: 30,
        letterSpacing: 0.3,
        fontWeight: 400,
        color: 'black'
    },
    sub_heading: {
        fontSize: 18,
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 10,
        paddingRight: 10,
        color: '#444444'
    },
    button_div: {
        display:'block', 
        justify: 'center',
        color: '#444444'
    },
    login_button: {
        paddingTop: 20, 
        paddingBottom: 20
    }
}));

const Login = () => {


    const classes = useStyles()
    console.log("in home page")
    const { isAuthenticated } = useAuth0();
    const [index, setIndex] = React.useState(0)


    return (
        <>
            <Grid container spacing={0} className={classes.welcome_header}>
                <Grid xs={12} item container justify="center" alignItems="center">
                    <div className={classes.button_div}>
                        Welcome to Data Facade management console<br/>
                        Please login with your data-facade email<br/>
                        <center className={classes.login_button}>
                            <LoginButton />
                        </center>
                    </div>
                </Grid>    
            </Grid>
        </>
    )
}

export default Login